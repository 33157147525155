import { Component, Input } from '@angular/core';

@Component({
  selector: 'cs-spinner',
  standalone: true,
  imports: [],
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.scss'
})
export class SpinnerComponent {
  @Input() color: 'pink' | 'slate' | 'white' = 'slate';
  @Input() size: '5' | '6' | '8' | '12' | '16' = '5';

  colorClasses = {
    pink: 'text-pink-300',
    slate: 'text-slate-300',
    white: 'text-white',
  };

  sizeClasses = {
    '5': 'w-5 h-5',
    '6': 'w-6 h-6',
    '8': 'w-8 h-8',
    '12': 'w-12 h-12',
    '16': 'w-16 h-16',
  };

}
